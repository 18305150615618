import api from './api';

function obterAnoExercicio(){
    return new Promise((resolve, reject) => {
        return api.get(`exercicio-financeiro/listar`)
        .then(response => resolve(response))
        .catch(error => reject(error));
    });
}


function cadastrarAnoExercicio(ano){
    return new Promise((resolve, reject) => {
        return api.post('exercicio-financeiro/gravar', ano)
        .then(response => resolve(response))
        .catch(error => reject(error));
    });
}
function excluirAnoExercicio(id){
    return new Promise((resolve, reject) => {
        return api.delete(`exercicio-financeiro/excluir/${id}`)
        .then(response => resolve(response))
        .catch(error => reject(error));
    });
}

export default {
    obterAnoExercicio,
    cadastrarAnoExercicio,
    excluirAnoExercicio
}

