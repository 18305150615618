<template>
    <section>
        <div class="titulo">
            <div class="margem container">
                <h2>Plano de Contas</h2>
    
            </div>
        </div>
    
    
    
        <div class="margem container">
            <div class="bloco2 margem" style="cursor: pointer; font-size: medium">
    
                <ul style="margin-bottom: 0rem;">
                    <template v-for="(grupo, index) in grupos" :key="index">
                                    <li class="grupo">
                                        
                
                                        <i class="fa-regular"
                                            :class="{ 'fa-square-caret-down': grupo.expandido, 'fa-square-caret-right': !grupo.expandido }"
                                            @click="toggleExpandir(grupo)" style="cursor: pointer;"></i> <span
                                            @click="toggleExpandir(grupo)" style="cursor: pointer;"
                                            @mouseover="mostrarOuOcultarBotao(grupo.id, 'grupo', true)"
                                            @mouseleave="mostrarOuOcultarBotao(grupo.id, 'grupo', false)">{{ grupo.descricaoDRE }}
                
                
                                            <i class="fa-solid fa-circle-minus" style="color: var(--cor-erro); margin-left: 0.5rem; opacity: 0;"
                                                :id="'apagargrupo' + grupo.id" @click.stop
                                                @click="abrirModalRemover('grupo', grupo)"></i>
                                        </span>
                
                                        <ul v-if="grupo.expandido" class="subgrupo" style="margin-bottom: 0rem;">
                                            <li v-for="(subgrupo, subindex) in grupo.subgrupo" :key="subindex">
                                                <i class="fa-regular"
                                                    :class="{ 'fa-square-caret-down': subgrupo.expandido, 'fa-square-caret-right': !subgrupo.expandido }"
                                                    @click="toggleExpandir(subgrupo)" style="cursor: pointer;"></i> <span
                                                    @click="toggleExpandir(subgrupo)"
                                                    @mouseover="mostrarOuOcultarBotao(subgrupo.id, 'Subgrupo', true)"
                                                    @mouseleave="mostrarOuOcultarBotao(subgrupo.id, 'Subgrupo', false)">
                                                    {{ subgrupo.descricaoDRE }}
                
                
                                                    <i title="Apagar subgrupo" class="fa-solid fa-circle-minus"
                                                        style="color: var(--cor-erro); opacity: 0; margin-left: 0.5rem;"
                                                        :id="'apagarSubgrupo' + subgrupo.id" @click.stop
                                                        @click="abrirModalRemover('subgrupo', subgrupo)"></i>
                
                                                </span>
                
                
                                                <ul v-if="subgrupo.expandido" class="categoria" style="margin-bottom: 0rem;">
                                                    <li v-for="(categoria, catIndex) in subgrupo.categoriaDRE " :key="catIndex">
                                                        <span @mouseover="mostrarOuOcultarBotao(categoria.id, 'Categoria', true)"
                                                            @mouseleave="mostrarOuOcultarBotao(categoria.id, 'Categoria', false)">{{
                                                                categoria.descricao }}
                
                                                            <i class="fa-solid fa-circle-minus" title="Apagar categoria"
                                                                style="color: var(--cor-erro); opacity: 0; margin-left:0.5rem;"
                                                                :id="'apagarCategoria' + categoria.id"
                                                                @click="abrirModalRemover('categoria', categoria, subgrupo)"></i>
                                                        </span>
                                                    </li>
                                                    <li>
                                                        <div style="align-items: center; display: flex;">
                                                            <i class="fa-solid fa-square-plus"
                                                                :id="'adicionarCategoria' + subgrupo.codigoDRE"
                                                                style="color: var(--cor-sucesso); margin-right: 3px;"></i>
                                                            <i class="fa-regular fa-square-caret-right"
                                                                :id="'setaCategoria' + subgrupo.codigoDRE"
                                                                style="cursor: pointer; display: none;"></i>
                                                            <button :id="'botaoCategoria' + subgrupo.codigoDRE"
                                                                @click="adicionarItem('Categoria', subgrupo.codigoDRE, true)"
                                                                class="acao-secundaria" style="padding: 0rem; border: none;">
                                                                Nova Categoria</button>
                                                            <input :id="'inputCategoria' + subgrupo.codigoDRE" type="text"
                                                                placeholder="Nome da categoria" v-model="nomeCategoria"
                                                                @keyup.enter="adicionarCategoria($event.target.value, subgrupo), this.nomeCategoria = ''"
                                                                @focusout="adicionarItem('Categoria', subgrupo.codigoDRE, false)"
                                                                style="width: 12rem; height: 1rem ;display: none; font-size: 15px; margin-left: 5px; padding-left: 3px; outline: none; border-radius: 4px; border: 1px solid grey;">
                                                        </div>
                                                    </li>
                                                </ul>
                                            </li>
                                            <li>
                                                <div >
                                                    <i class="fa-solid fa-square-plus" :id="'adicionarGrupo' + grupo.id"
                                                        style="color: var(--cor-sucesso);margin-right: 3px;"></i>
                                                    <i class="fa-regular fa-square-caret-right" :id="'setaGrupo' + grupo.id"
                                                        style="cursor: pointer; display: none;"></i>
                                                    <button :id="'botaoGrupo' + grupo.id"
                                                        @click="adicionarItem('Grupo', grupo.id, true)" class="acao-secundaria"
                                                        style="padding: 0rem; border: none">
                                                        Novo Subgrupo</button>
                                                    {{ grupoDeContas }}
                                                    <input :id="'inputGrupo' + grupo.id" type="text" placeholder="nome do subgrupo"
                                                        v-model="nomeSubgrupo" @focusout="adicionarItem('Grupo', grupo.id, false)"
                                                        @keyup.enter="adicionarSubgrupo(this.nomeSubgrupo, grupo)"
                                                        style="width: 12rem;height: 1rem ;display: none; font-size: 15px; margin-left: 5px; padding-left: 3px; outline: none; border-radius: 4px; border: 1px solid grey;">
                                                </div>
                                            </li>
                                        </ul>
                                    </li>
</template>
                <div  >
                    <i class="fa-solid fa-square-plus" id="adicionarGrupo" style="color: var(--cor-sucesso);margin-right: 3px;"></i>
                    <i class="fa-regular fa-square-caret-right" id="setaGrupo"
                        style="cursor: pointer; display: none;"></i>
                    <button id="botaoGrupo" @click="adicionarItem('Grupo', '', true)" class="acao-secundaria" 
                        style="padding: 0rem; border: none;">
                        Novo grupo</button>
                    <input id="inputGrupo" type="text" placeholder="nome do grupo" v-model="novoGrupo"
                        @focusout="adicionarItem('Grupo', '', false)"
                        @keyup.enter="adicionarGrupo(this.novoGrupo, this.novoGrupo = null)"
                        style="width: 12rem; height: 1rem ;display: none; font-size: 15px; margin-left: 5px; padding-left: 3px; outline: none; border-radius: 4px; border: 1px solid grey;">
                </div>
            </ul>
        </div>

   <!-- MODAL ADICIONAR CATEGORIA -->
<div class="modal-mask" v-if="modalArea && addModalType === 'categoria'" @click="fecharModalFora">
    <div class="modal-container" style="height: min-content; width: 50rem;">
        <div style="display: flex; justify-content: center">
            <h3>Adicionar Categoria</h3>
        </div>
        <br>
        <div class="modal-body">
            <label for="categoria"> Nome da Categoria: </label>
            <input v-model="nomeCategoria" type="text" id="categoria" class="form-control">
            <br>
            <div class="modal-footer">
                <button type="button" @click="adicionarCategoria" data-bs-dismiss="modal">Confirmar</button>&nbsp;&nbsp;
                <button type="button" class="acao-secundaria" @click="cancelarModal" data-bs-dismiss="mask">Cancelar</button>
            </div>
        </div>
    </div>
</div>
<!-- END MODAL ADICIONAR CATEGORIA -->

<!-- MODAL ADICIONAR GRUPO DE CONTAS -->
<div class="modal-mask" v-if="modalArea && addModalType === 'grupo'" @click="fecharModalFora">
    <div class="modal-container" style="height: min-content; width: 50rem;">
        <div style="display: flex; justify-content: center">
            <h3>Adicionar Grupo de Contas</h3>
        </div>
        <br>
        <div class="modal-body">
            <label for="grupo"> Nome do Grupo de Contas: </label>
            <input v-model="grupoDeContas" type="text" id="grupo" class="form-control">
            <br>
            <div class="modal-footer">
                <button type="button" @click="adicionarGrupo" data-bs-dismiss="modal">Confirmar</button>&nbsp;&nbsp;
                <button type="button" class="acao-secundaria" @click="cancelarModal" data-bs-dismiss="mask">Cancelar</button>
            </div>
        </div>
    </div>
</div>
<!-- END MODAL ADICIONAR GRUPO DE CONTAS -->

<!-- MODAL ADICIONAR SUBGRUPO DE CONTAS -->
<div class="modal-mask" v-if="modalArea && addModalType === 'subgrupo'" @click="fecharModalFora">
    <div class="modal-container" style=" width: 20rem; height: 10rem !important;">
        <div style="display: flex; justify-content: center">
            <h3>Adicionar Subgrupo de Contas</h3>
        </div>
        <br>
        <div class="modal-body">
            <label for="subgrupo"> Nome do Subgrupo de Contas: </label>
            <input v-model="grupoDeContas" type="text" id="subgrupo" class="form-control">
            <br>
            <div class="modal-footer">
                <button type="button" @click="adicionarSubgrupo" data-bs-dismiss="modal">Confirmar</button>&nbsp;&nbsp;
                <button type="button" class="acao-secundaria" @click="cancelarModal" data-bs-dismiss="mask">Cancelar</button>
            </div>
        </div>
    </div>
</div>
<!-- END MODAL ADICIONAR SUBGRUPO DE CONTAS -->

<!-- MODAL REMOVER GRUPO DE CONTAS -->
<div class="modal-mask" v-if="modalArea && removeModalType === 'grupo'" @click="fecharModalFora">
    <div class="modal-container" style=" width: 20rem; height: 10rem !important;">
        <div class="modal-body">
            <span>Confirma a exclusão do Grupo?</span>
            <br><br>
            <div class="modal-footer">
                <button type="button" @click="excluirGrupo">Confirmar</button>&nbsp;&nbsp;
                <button type="button" @click="cancelarModal" class="acao-secundaria">Cancelar</button>
            </div>
        </div>
    </div>
</div>
<!-- END MODAL REMOVER GRUPO DE CONTAS -->

<!-- MODAL REMOVER SUBGRUPO DE CONTAS -->
<div class="modal-mask" v-if="modalArea && removeModalType === 'subgrupo'" @click="fecharModalFora">
    <div class="modal-container" style=" width: 20rem; height: 10rem !important;">
       
        <div class="modal-body">
            <span>Confirma a exclusão do Subgrupo?</span>
            <br><br>
            <div class="modal-footer">
                <button type="button" @click="excluirSubgrupo">Confirmar</button>&nbsp;&nbsp;
                <button type="button" @click="cancelarModal" class="acao-secundaria">Cancelar</button>
            </div>
        </div>
    </div>
</div>
<!-- END MODAL REMOVER SUBGRUPO DE CONTAS -->

<!-- MODAL REMOVER CATEGORIA DE CONTAS -->
<div class="modal-mask" v-if="modalArea && removeModalType === 'categoria'" @click="fecharModalFora">
    <div class="modal-container" style=" width: 20rem; height: 10rem !important;">
        <div class="modal-body">
            <span>Confirma a exclusão da Categoria?</span>
            <br><br>
            <div class="modal-footer">
                <button type="button" @click="excluirCategoria">Confirmar</button>&nbsp;&nbsp;
                <button type="button" @click="cancelarModal" class="acao-secundaria">Cancelar</button>
            </div>
        </div>
    </div>
</div>
<!-- END MODAL REMOVER CATEGORIA DE CONTAS -->
    </div>

        </section>
</template>

<script>
import estruturaService from '../../services/estrutura-service'

export default {
    data() {
        return {


            novoGrupo: null,
            addModalType: null,
            removeModalType: null,
            targetGroup: null,
            modalArea: false,
            opcao: 'adicionar',
            grupos: [],
            grupoDeContas: '',
            idGrupoRemover: '',
            idSubgrupo: '',
            SubgrupoRemover: '',
            nomeCategoria: '',
            CategoriaRemover: '',
            nomeSubgrupo: '',
            subGrupoCategoriaRemover: ''
        }
    },

    mounted() {
        this.obterEstrutura()
    },

    methods: {
        mostrarOuOcultarBotao(id, tipo, mostrar) {
            if (mostrar == true) {
                document.getElementById(`apagar${tipo}${id}`).style.opacity = 1
            } else {
                document.getElementById(`apagar${tipo}${id}`).style.opacity = 0
            }
        },

        adicionarItem(tipo, id, adicionar) {
            if (adicionar == true) {

                if (tipo !== 'Categoria') {
                    document.getElementById(`seta${tipo}${id}`).style.display = '';
                }
                document.getElementById(`input${tipo}${id}`).style.display = '';
                document.getElementById(`input${tipo}${id}`).focus();
                document.getElementById(`botao${tipo}${id}`).style.display = 'none';
                document.getElementById(`adicionar${tipo}${id}`).style.display = 'none';
            } else {
                if (tipo !== 'Categoria') {
                    document.getElementById(`seta${tipo}${id}`).style.display = 'none';
                }
                document.getElementById(`input${tipo}${id}`).style.display = 'none';
                // document.getElementById(`inputGrupo${id}`).focus();
                document.getElementById(`botao${tipo}${id}`).style.display = '';
                document.getElementById(`adicionar${tipo}${id}`).style.display = '';
            }

        },

        adicionarCategoria(descricao, targetGroup) {
            const payload = {
                "subGrupo_id": targetGroup.id,
                "desc": descricao
            };

            estruturaService.associarCategoriaGrupo(payload)
                .then((response) => {
                    console.log("Categoria adicionada com sucesso:", response);
                    response = response.data
                    var novoItem = {
                        id: response.id,
                        grupoDRE_id: response.grupoDRE_id,
                        codigo: response.codigo,
                        descricao: response.descricao,
                        categoriaItem: []
                    }

                    this.grupos.find(item => item.id == targetGroup.grupoDRE_pai).subgrupo.find(item => item.id == targetGroup.id).categoriaDRE.push(novoItem)

                })
                .catch((error) => {
                    console.error("Erro ao adicionar categoria:", error);
                });
        },

        excluirCategoria() {
            const id = this.CategoriaRemover.id;
            estruturaService.excluirCategoriaGrupo(id)
                .then((response) => {
                    console.log("Categoria excluída com sucesso:", response);
                    var categoriaDRE = this.grupos.find(item => item.id == this.subGrupoCategoriaRemover.grupoDRE_pai).subgrupo.find(item => item.id == this.subGrupoCategoriaRemover.id).categoriaDRE
                    this.grupos.find(item => item.id == this.subGrupoCategoriaRemover.grupoDRE_pai).subgrupo.find(item => item.id == this.subGrupoCategoriaRemover.id).categoriaDRE = categoriaDRE.filter(item => item.id !== this.CategoriaRemover.id)
                    this.modalArea = false;
                })
                .catch((error) => {
                    console.error("Erro ao excluir categoria:", error);
                });
        },

        excluirSubgrupo() {
            console.log(this.SubgrupoRemover.id)

            estruturaService.excluirGrupoEstrutura(this.SubgrupoRemover.id)
                .then((response) => {
                    console.log("Subgrupo excluído com sucesso:", response);
                    this.grupos.find(item => item.id == this.SubgrupoRemover.grupoDRE_pai).subgrupo = this.grupos.find(item => item.id == this.SubgrupoRemover.grupoDRE_pai).subgrupo.filter(item => item.id !== this.SubgrupoRemover.id)
                    this.modalArea = false;
                })
                .catch((error) => {
                    console.error("Erro ao excluir subgrupo:", error);
                    alert('Erro ao excluir subgrupo: ' + error.message);
                });
        },

        adicionarSubgrupo(descricao, targetGroup) {
            const payload = {
                "dre_pai": targetGroup.id,
                "desc": descricao
            };

            estruturaService.criarGrupoEstrutura(payload)
                .then((response) => {
                    console.log("Subgrupo adicionado com sucesso:", response);
                    response = response.data
                    var novoItem = {
                        id: response.id,
                        grupoDRE_pai: response.grupoDRE_pai,
                        codigoDRE: response.codigoDRE,
                        descricaoDRE: response.descricaoDRE,
                        nivelDRE: response.nivelDRE,
                        categoriaDRE: []
                    }
                    this.grupos.find(item => item.id == targetGroup.id).subgrupo.push(novoItem)

                    this.teste = response
                })
                .catch((error) => {
                    console.error("Erro ao adicionar subgrupo:", error);
                });

            this.nomeSubgrupo = ''
        },

        excluirGrupo() {
            const id = this.idGrupoRemover;
            estruturaService.excluirGrupoEstrutura(id)
                .then((response) => {
                    console.log("Grupo excluído com sucesso:", response);
                    this.grupos = this.grupos.filter(item => item.id !== id)
                    this.modalArea = false;
                })
                .catch((error) => {
                    console.error("Erro ao excluir grupo:", error);
                });
        },

        adicionarGrupo(descricao) {
            const payload = {
                "dre_pai": null,
                "desc": descricao
            };

            estruturaService.criarGrupoEstrutura(payload)
                .then((response) => {
                    console.log("Grupo adicionado com sucesso:", response);
                    response = response.data
                    var novoItem = {
                        id: response.id,
                        grupoDRE_pai: response.grupoDRE_pai,
                        codigoDRE: response.codigoDRE,
                        descricaoDRE: response.descricaoDRE,
                        nivelDRE: response.nivelDRE,
                        subgrupo: []
                    };

                    this.grupos.push(novoItem);
                    this.modalArea = false;
                })
                .catch((error) => {
                    console.error("Erro ao adicionar grupo:", error);
                });
        },

        obterEstrutura() {
            estruturaService.obterEstruturaSemAno()
                .then((response) => {
                    this.grupos = response.data.map(grupo => {
                        if (grupo.subgrupo) {
                            grupo.subgrupo.forEach(subgrupo => {
                                if (subgrupo.categoriaDRE) {
                                    subgrupo.categoriaDRE.forEach(categoria => {
                                        categoria.descricaoCompleta = `${subgrupo.descricaoDRE}: ${categoria.descricao}`;
                                    });
                                }
                            });
                        }
                        return grupo;
                    });
                })
                .catch(error => {
                    console.log(error)
                })
        },

        toggleAddRemove() {
            if (this.opcao === 'adicionar') {
                this.addModalType = this.targetGroup ? 'grupo' : null;
                this.removeModalType = null;
            } else if (this.opcao === 'remover') {
                this.removeModalType = this.targetGroup ? 'grupo' : null;
                this.addModalType = null;
            }
        },

        toggleExpandir(item) {
            item.expandido = !item.expandido;
        },

        abrirModal(tipo, grupoPai) {
            this.addModalType = tipo;
            this.targetGroup = grupoPai;
            this.idSubgrupo = tipo === 'subgrupo' ? grupoPai.id : null;
            // this.modalArea = true;
            this.grupoDeContas = '';
            this.nomeCategoria = '';
            this.nomeSubgrupo = '';
            this.adicionarSubgrupo()
            console.log(this.targetGroup);
        },

        abrirModalRemover(tipo, item, subgrupo) {
            this.removeModalType = tipo;
            this.targetGroup = item;

            if (tipo === 'grupo') {
                this.idGrupoRemover = item.id;
            } else if (tipo === 'subgrupo') {
                this.SubgrupoRemover = item;
            } else if (tipo === 'categoria') {
                this.CategoriaRemover = item;
                this.subGrupoCategoriaRemover = subgrupo
            }

            this.modalArea = true;
        },

        cancelarModal() {
            this.modalArea = false;
        },

        fecharModalFora(event) {
            if (event.target.classList.contains('modal-mask')) {
                this.modalArea = false;
            }
        }
    }
};
</script>

<style scoped>
.grupo {
    list-style: none;
}

.subgrupo {
    list-style: none;
}


</style>