<template>
    <body>
        <main id="main">
            <section>
                <div class="titulo">
                    <div class="margem container">
                        <h2>Gerenciamento Responsável</h2>
                    </div>
                </div>
                <div class="grid-2 margem container">
                    <div class="bloco2 margem" style="font-size: medium;">
                        <!-- <div style="display: flex; flex-flow: row; font-size: medium"> -->
                           
                            <!-- TREE VIEW-->
                            <div>
                                <ul>
                                    <template v-for="(grupo, index) in grupos" :key="index">
                                    <li  style="list-style: none;">
                                        <span @click="toggleExpandir(grupo)" style="cursor: pointer;">
                                            <i class="fa-regular" :class="{'fa-square-caret-down': grupo.expandido, 'fa-square-caret-right': !grupo.expandido}"></i>
                                            {{ grupo.descricaoDRE }}
                                        </span>
                                        <ul v-if="grupo.expandido && grupo.subgrupo"  style="list-style: none;">
                                            <li v-for="(subgrupo, subindex) in grupo.subgrupo" :key="subindex">
                                                <span @click="toggleExpandir(subgrupo)" style="cursor: pointer;">
                                                    <i class="fa-regular" :class="{'fa-square-caret-down': subgrupo.expandido, 'fa-square-caret-right': !subgrupo.expandido}"></i>
                                                    {{ subgrupo.descricaoDRE }}
                                                </span>
                                                <ul v-if="subgrupo.expandido && subgrupo.categoriaDRE" style="cursor: pointer;">
                                                    <li v-for="(categoria, catIndex) in subgrupo.categoriaDRE" :key="catIndex" @click="categoriaSelecionada(categoria)">{{ categoria.descricao }}</li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </li>
</template>
            </ul>
        </div>
                    </div>
        <!-- <div style="margin-left: 25rem;"> -->
        <div class="bloco2 margem">
            <div v-if="selectedCategory"  >
                <div class="alinha-centro" style="font-size: medium;"> 
                    <span >Responsáveis:</span>&nbsp;
                    <span  ><b>{{ selectedCategory.descricao }}</b></span>
                </div>
                  
               
                <br>     <br>
              
                        <input v-model="searchQuery" @focusin="filtrarUsers()" @input="filtrarUsers" @focusout="fecharLista()" placeholder="Pesquisar colaborador por nome" type="text" class="form-control" />
                    <div v-if="filteredUsers" style="z-index: 99999; height: fit-content; max-height: 10rem; overflow: auto; background-color: var(--cor-bg); border-bottom-left-radius: 15px; border-bottom-right-radius: 15px; position: absolute; width: 17.5rem; border: 1px solid var(--cor-separador);">
                        <ul style="list-style: none;">
                            <li v-for="user in filteredUsers" :key="user.id">
                                <div @click="toggleUsuarioResponsavel(user)" style="display: flex; align-items: center; padding: 5px; border-radius: 10px; margin-right: 3rem;" :style="{'color': isUsuarioResponsavel(user) ? 'var(--cor-fonte-fraca)' : 'var(--cor-fonte-forte)', 'cursor': isUsuarioResponsavel(user) ? 'not-allowed' : 'pointer'}">
                                    {{ user.name }}
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <br>
                <div class="responsaveis-list" style="max-height: 200px; overflow-y: auto; overflow-x: hidden;">
                    <ul style="display: flexbox; ">
                        <div class="row row-cols-auto">
                            <div style="width: fit-content; border: 1px solid black; border-radius: 10px; padding: 5px; margin: 5px;" class="col" v-for="(responsavel, index) in usuariosAdicionados" :key="index">
                                <li style="list-style: none;">{{ responsavel.name }}
                                    <i class="fa-solid fa-circle-xmark" style="color: var(--cor-erro);" @click="desassociarUsuario(responsavel)"></i>
                                </li>
                            </div>
                        </div>
                    </ul>
                </div>
          

           
     
        <!-- END CARDS -->
    </div>
    
 </div>
            
            </section>
    </main>
</body>
</template>

<script>
import estruturaService from '@/services/estrutura-service';
import pessoaService from '@/services/pessoa-service';
import { api } from "roboflex-thalamus-request-handler";

export default {
    data() {
        return {
            teste: 'teste',
            filteredUsers: null,
            searchQuery: null,
            selectedCategory: '',
            modalArea: false,
            responsaveis: '',
            filtroNome: '',
            grupos: [],
            usuariosAdicionados: [],
        };
    },

    mounted() {
        this.obterEstrutura()
        this.fetchUsers()
    },

    computed: {
        pessoasFiltradas() {
            const buscaNome = this.filtroNome.toLowerCase();
            return this.responsaveis.filter(item =>
                item.nomeCompleto.toLowerCase().includes(buscaNome)
            );
        },
    },

    methods: {
        fetchUsers() {
            api.get('usuario')
                .then(response => {
                    this.responsaveis = response.data.map(user => ({
                        id: user.id,
                        name: user.name
                    }));
                })
                .catch(error => {
                    console.error('Error fetching users:', error);
                });
        },

        fecharLista() {
            setTimeout(() => {
                this.filteredUsers = null;
                this.searchQuery = null;
            }, 200);
        },

        filtrarUsers() {
            this.filteredUsers = this.responsaveis;
            if (!this.searchQuery) {
                this.filteredUsers = this.responsaveis;
            } else {
                const textoLowerCase = this.searchQuery.toLowerCase();
                this.filteredUsers = this.responsaveis.filter(user => {
                    return Object.values(user).some(value => {
                        if (typeof value === 'string') {
                            return value.toLowerCase().includes(textoLowerCase);
                        }
                        return false;
                    });
                });
            }
        },

        obterEstrutura(ano) {
            estruturaService.obterEstrutura(ano)
                .then((response) => {
                    this.grupos = response.data.map(grupo => {
                        if (grupo.subgrupo) {
                            grupo.subgrupo.forEach(subgrupo => {
                                if (subgrupo.categoriaDRE) {
                                    subgrupo.categoriaDRE.forEach(categoria => {
                                        categoria.descricaoCompleta = `${subgrupo.descricaoDRE}: ${categoria.descricao}`;
                                    });
                                }
                            });
                        }
                        return grupo;
                    });
                })
                .catch(error => {
                    console.log(error)
                })
        },

        toggleExpandir(item) {
            this.expandirRecursivamente(item, !item.expandido);
        },

        expandirRecursivamente(item, expandido) {
            item.expandido = expandido;
            if (item.subgrupos) {
                item.subgrupos.forEach(subgrupo => {
                    this.expandirRecursivamente(subgrupo, expandido);
                });
            }
        },

        categoriaSelecionada(categoria) {
            this.selectedCategory = categoria;
            this.usuariosAdicionados = categoria.categoriaResponsavel.map(responsavel => ({
                id: responsavel.id,
                name: responsavel.nome
            }));
        },

        isUsuarioResponsavel(user) {
            return this.usuariosAdicionados.some(responsavel => responsavel.id === user.id);
        },

        toggleUsuarioResponsavel(user) {
            if (this.isUsuarioResponsavel(user)) {
                this.desassociarUsuario(user);
            } else {
                this.associarUsuario(user);
            }
        },

        associarUsuario(user) {
            const payload = {
                categoriaDRE_id: this.selectedCategory.id,
                responsavel_id: user.id
            };

            pessoaService.associarResponsavel(payload)
                .then(response => {
                    console.log(response)
                    this.usuariosAdicionados.push({ id: user.id, name: user.name });

                    const categoria = this.selectedCategory;
                    categoria.categoriaResponsavel.push({
                        id: user.id,
                        nome: user.name
                    });
                })
                .catch(error => {
                    console.error('Error associating user:', error);
                });
        },

        desassociarUsuario(user) {
            const payload = {
                categoriaDRE_id: this.selectedCategory.id,
                responsavel_id: user.id
            };
            pessoaService.excluirResponsavel(payload)
                .then(() => {
                    this.usuariosAdicionados = this.usuariosAdicionados.filter(responsavel => responsavel.id !== user.id);

                    const categoria = this.selectedCategory;
                    categoria.categoriaResponsavel = categoria.categoriaResponsavel.filter(responsavel => responsavel.id !== user.id);
                })
                .catch(error => {
                    console.error('Error disassociating user:', error);
                });
        },

        abrirModal() {
            this.modalArea = true;
            const cardContainer2 = document.querySelector('.card-container2');
            if (cardContainer2) {
                cardContainer2.style.zIndex = '9999';
            }
        },

        fecharModalFora(event) {
            if (event.target.classList.contains('modal-mask')) {
                this.modalArea = false;
            }
        },

        fecharModal() {
            this.modalArea = false;
        }
    }
}
</script>

<style>
.modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal-container {
    max-height: 80%;
    width: 70%;
    padding: 3rem;
    overflow-y: auto;
    background-color: var(--cor-bg);
    border-radius: 20px;
    height: 20rem !important;
    overflow: hidden;
}
</style>
