<template>
    <section>
        <div class="titulo">
            <div class="margem container">
                <div class="m-icone direita">
                    <div class="pesquisa" v-if="excelData.length > 0">
                        <input type="text" v-model="searchTerm" placeholder="Pesquisar categoria por nome" />
                        <a class="icone-pesquisa" title="Pesquise"></a>
                    </div>
                </div>
                <h2>Orçamento Planilha</h2>
            </div>
        </div>
        <div class="margem container">
            <div class="bloco margem" style="cursor: pointer;">
                <div class="tags m-b">
                    <a @click="manual" class="">Manual</a>
                    <a @click="planilha" class="ativo">Planilha</a>
                </div>
    
                <div class="bloco margem">
                    <div class="grid margem">
                        <label>Ano / Exercício:</label>
                        <select style="width: 200px; padding: 5px;" v-model="anoSelecionado">
                                                <option value="" disabled>Selecione</option>
                                                <option v-for="item in anos" :key="item.ano" :value="item.ano">
                                                    {{ item.ano }}
                                                </option>
                                            </select>&nbsp;
                        <i title="Clique para adicionar Ano / Exercício" class="fa-solid fa-circle-plus" style="color: var(--cor-sucesso);" @click="abrirModal"></i>&nbsp;&nbsp;
                        <button style="padding: 12px;" @click="mostrarHistorico">Histórico&nbsp;</button>&nbsp;
                        <button style="padding: 12px;" @click="mostrarModalEdicao">Download Planilha Modelo </button>
                    </div>
                </div>
    
                <div class="bloco margem">
                    <div class="alinha-centro">
                        <label>
                                                <input type="checkbox" v-model="emEdicao" @change="atualizarStatus">&nbsp;Ativar Modo Edição
                                            </label>
                        <br>
                        <span style="color: var( --cor-erro);" v-if="ultimoStatus !== null && emEdicao">
                                                Usuário: {{ ultimoStatus.usuario_nome }} <br>
                                                Data/Hora: {{ formatarData(ultimoStatus.dtRegistro) }}
                                            </span>
                    </div>
    
                    <div style="text-align: center;">
                        <br>
                        <input @change="handleFileUpload" :disabled="emEdicao" ref="fileInput" style="display: none; " type="file" >
                        <a style="cursor: pointer; border: 1px solid;" class="icone-upload" @click="openFileInput"> Escolher arquivo</a>
    
    
                    
                        <!-- <input type="file" @change="handleFileUpload" :disabled="emEdicao" accept=".xlsx, .xls">
                        <span v-if="fileName">{{ fileName }}</span> -->
                        <i @click="fecharArquivo" v-if="excelData.length > 0" class="fa-regular fa-circle-xmark"></i> <br><br>
                    </div>
                </div>
                <br>
                <div class="alinha-centro">
                    <button v-if="excelData.length > 0" style="text-align: center;" @click="salvarPlanilha">Salvar Planilha </button>
    
                </div>
                <table class="tabela">
                    <thead>
                        <tr>
                            <th v-for="(header, index) in filteredExcelData[0]" :key="index" class="header-fixed">{{ header }}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(row, index) in filteredExcelData.slice(1)" :key="index">
                            <td v-for="(value, key) in row" :key="key">{{ formatarValor(value) }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    
        <!-- MODAL ANO/EXERCICIO -->
        <div class="modal-mask" v-if="modalArea" @click="fecharModalFora">
            <div class="modal-container" style="height: min-content; width: 50rem;">
                <div class="modal-content">
    
                    <div class="modal-header">
                        <h3 class="modal-title fs-5">Adicionar Ano / Exercício:</h3>
    
                    </div>
                    <div class="modal-body">
    
                        <input style="width: 200px; padding: 5px;" type="text" v-model="ano" placeholder="" />&nbsp;
                        <button style="padding: 5px 10px;" @click="adicionarAno">Adicionar</button>
                        <br><br>
                        <div class="bloco2 margem2" style="overflow: auto; max-height: 15rem;">
                            <br>
                            <ul>
                                <li v-for="ano in anos" :key="ano.id">
                                    {{ ano.ano }}
                                    <span @click="excluirAno(ano.id)" @mouseover="mostrarOuOcultarBotao(ano.id, 'Ano', true)" @mouseleave="mostrarOuOcultarBotao(ano.id, 'Ano', false)" class="icon-trash">
                                                        <i class="fa-solid fa-trash" :id="'apagarAno' + ano.id" style="color: var( --cor-erro); opacity: 0;"></i>
                                                    </span>
                                    <br>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <br>
                <div class="modal-footer alinha-direita">
                    <!-- <button type="button" @click="fecharModal" class="acao-secundaria">Cancelar</button> -->
                </div>
    
            </div>
        </div>
        <!-- END MODAL ANO/EXERCICIO -->
    
        <!-- MODAL HISTÓRICO DE VERSÃO -->
        <div v-if="modalHistorico" class="modal-mask" @click="fecharModalFora">
            <div class="modal-container" id="staticBackdrop" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true" style=" height: 50vh !important;">
                <div class="modal-content">
                    <div class="modal-header">
                        <h3 class="modal-title fs-5" id="staticBackdropLabel">Histórico de Versão</h3>
                    </div>
    
                    <div class="modal-body">
                        <div class="grid-2 margem bloco">
                            <div class="grid-3 margem">
                                <div>
                                    <label for="filtroAno">Filtrar por Ano:</label>
                                    <select v-model="filtroAno">
                                                        <option value="">Todos</option>
                                                        <option v-for="item in anos" :key="item.ano" :value="item.ano">{{ item.ano }}</option>
                                                    </select>
                                </div>
                                <div>
                                    <label for="filtroUsuario">Filtrar por Usuário:</label>
                                    <input type="text" v-model="filtroUsuario" placeholder="Nome do Usuário">
    
                                </div>
                                <div>
                                    <label for="filtroData">Filtrar por Data:</label>
                                    <input type="date" v-model="filtroData">
                                </div>
    
    
    
                            </div>
                        </div>
    
                        <div class="table-container" style="max-height: 30vh !important ;overflow-y: auto !important;">
                            <table class="tabela alinha-centro">
                                <thead>
                                    <tr>
                                        <th>Usuário</th>
                                        <th>Arquivo</th>
                                        <th>Data de Upload</th>
                                        <th>Download</th>
                                        <th>Restaurar</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(item, index) in filteredHistorico" :key="index">
                                        <td>{{ item.usuario_nome }}</td>
                                        <td>{{ item.path }}</td>
                                        <td>{{ formatarData(item.dtUpload) }}</td>
                                        <td>
                                            <a :href="`${rootStorage}${item.path}`" download>
                                                                    <i class="fa-solid fa-download" title="Baixar Arquivo"></i>
                                                                </a>
                                        </td>
                                        <td><i class="fa-solid fa-clock-rotate-left" @click="sendFileToBackend(item, usuarioId)"></i></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- END MODAL HISTÓRICO DE VERSÃO -->
    
        <!-- MODAL EDIÇÃO-->
        <div v-if="modalEdicao" class="modal-mask" @click="fecharModalFora">
            <div class="modal-container" id="staticBackdrop" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true" style="max-width: 20%; max-height: 20%;">
                <div class="modal-content">
                    <div class="modal-header">
                        <h3 class="modal-title fs-5 alinha-centro" id="staticBackdropLabel">Deseja ativar o Modo Edição ?</h3>
                        <!-- <button type="button" class="btn-close" @click="fecharModalEdicao()" data-bs-dismiss="modal" aria-label="Close"></button> -->
                    </div>
                    <br>
                    <div class="modal-body">
                        <div class="row alinha-centro">
                            <div class="col d-flex justify-content">
                                <button @click="confirmarEdicao(true)">Sim</button>&nbsp;&nbsp;
                                <button class="acao-secundaria" @click="confirmarEdicao(false)">Não</button>
                                <br><br>
                            </div>
    
                        </div>
    
                        <div class="alinha-centro">
                            <!-- <span @click="downloadPlanilha" style="text-decoration: underline; cursor: pointer;">Download Planilha&nbsp;<i class="fa-solid fa-download alinha-centro"></i></span> -->
    
                           
                        
                        </div>
    
                        <br>
                    </div>
                </div>
            </div>
        </div>
        <!-- END MODAL EDIÇÃO -->
    </section>
</template>

<script>
import * as XLSX from 'xlsx';
import { api } from "roboflex-thalamus-request-handler";
import { createToaster } from "@meforma/vue-toaster";
import anoexercicioService from '@/services/ano-exercicio-service';
import statusService from '@/services/status-service';
import { ref } from 'vue';


const toaster = createToaster({
    position: "top-right",
    duration: "4000",
});

export default {
    data() {
        const currentYear = new Date().getFullYear();
        return {
            excelData: [],
            historico: [],
            modalHistorico: false,
            errorMessage: '',
            fileName: '',
            searchTerm: '',
            filtroAno: '',
            filtroData: '',
            filtroUsuario: '',
            anoSelecionado: currentYear,
            usuarioId: 8,
            emEdicao: false,
            ultimoStatus: null,
            meses: [
                'Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho',
                'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'
            ],
            modalArea: false,
            ano: '',
            config: {
                masked: false,
                prefix: 'R$ ',
                thousands: '.',
                decimal: ',',
                precision: 2,
            },
            vplanilha: '',
            anos: [],
            teste: 'teste',
            edicao: 1,
            modalEdicao: false,
            rootStorage: process.env.VUE_APP_ROOT_STORAGE,
        };
    },
    setup() {
        const fileInput = ref(null);

        const openFileInput = () => {
            fileInput.value.click();
        };

        return { fileInput, openFileInput };
    },

    mounted() {
        this.obterAnos();
        this.obterUltimoStatus();
    },
    methods: {
        mostrarOuOcultarBotao(id, tipo, mostrar) {
            if (mostrar) {
                document.getElementById(`apagar${tipo}${id}`).style.opacity = 1;
            } else {
                document.getElementById(`apagar${tipo}${id}`).style.opacity = 0;
            }
        },
        abrirModal() {
            this.modalArea = true;
        },
        fecharModal() {
            this.modalArea = false;
            this.modalHistorico = false;
            this.modalEdicao = false;
        },
        fecharModalFora(event) {
            if (event.target.classList.contains('modal-mask')) {
                this.fecharModal();
            }
        },
        confirmarEdicao(isConfirmed) {
            if (isConfirmed) {
                this.atualizarStatus();
            } else if (this.emEdicao) {
                this.atualizarStatus();

            }
            this.fecharModalEdicao();
        },
        handleFileUpload(event) {
            const file = event.target.files[0];
            const reader = new FileReader();
            this.fileName = file.name;

            reader.onload = (e) => {
                const data = new Uint8Array(e.target.result);
                const workbook = XLSX.read(data, { type: 'array' });
                const sheetName = workbook.SheetNames[0];
                const sheet = workbook.Sheets[sheetName];
                const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
                this.excelData = jsonData;
            };

            reader.readAsArrayBuffer(file);
        },
        async atualizarStatus() {
            const payload = { usuario_id: this.usuarioId };
            try {
                const response = await statusService.gravarStatus(payload);
                console.log(response);
                toaster.show(`Status atualizado com sucesso`, { type: "success" });
                this.downloadPlanilha()
                this.obterUltimoStatus();
            } catch (error) {
                console.error('Erro ao atualizar o status:', error);
                this.errorMessage = error.response.data.error || 'Erro ao atualizar o status';
            }
        },
        async sendFileToBackend(item) {
            const formData = new FormData();
            formData.append('arquivo', item.path);
            formData.append('usuario_id', 3);
            formData.append('ano', item.ano);

            try {
                const response = await api.post('dre/excel/restaurar', formData);
                console.log(response);
                toaster.show(`Arquivo restaurado com sucesso`, { type: "success" });
            } catch (error) {
                console.error('Erro ao restaurar o arquivo:', error);
                this.errorMessage = error.response.data.error || 'Erro ao restaurar o arquivo';
            }
        },
        validarPlanilha(data) {
            const currentMonth = new Date().getMonth() + 1;
            const currentYear = new Date().getFullYear();
            const selectedYear = this.anoSelecionado;

            console.log('Current Month:', currentMonth);
            console.log('Selected Year:', selectedYear);
            console.log('Current Year:', currentYear);

            if (selectedYear < currentYear) {
                return data;
            }

            const headers = data[0];
            console.log('Headers:', headers);

            const filteredData = data.map((row, rowIndex) => {
                if (rowIndex === 0) {
                    return row;
                }

                return row.map((cell, cellIndex) => {
                    if (cellIndex === 0) {
                        return cell;
                    }

                    const monthIndex = headers[cellIndex].match(/\d+/) ? parseInt(headers[cellIndex].match(/\d+/)[0]) : null;
                    console.log('Month Index:', monthIndex);

                    if (monthIndex && monthIndex < currentMonth) {
                        return '';
                    }
                    return cell;
                });
            });

            console.log('Filtered Data:', filteredData);
            return filteredData;
        },
        async salvarPlanilha() {
            const validData = this.validarPlanilha(this.excelData);
            const formData = new FormData();
            formData.append('arquivo', document.querySelector('input[type="file"]').files[0]);
            formData.append('usuario_id', this.usuarioId);
            formData.append('ano', this.anoSelecionado);
            formData.append('data', JSON.stringify(validData));

            try {
                const response = await api.post('dre/excel/importar', formData);
                console.log(response);
                toaster.show(`Arquivo enviado com sucesso`, { type: "success" });
            } catch (error) {
                console.error('Erro ao enviar o arquivo:', error);
                this.errorMessage = error.response.data.error || 'Erro ao enviar o arquivo';
            }
        },

        async obterUltimoStatus() {
            const payload = { ultimoStatus: this.edicao };

            try {
                const response = await statusService.getUltimoStatus(payload);
                this.ultimoStatus = response.data;
                this.emEdicao = response.data.edicao === 1;
                this.teste = this.ultimoStatus;
            } catch (error) {
                console.error('Erro ao obter o último status:', error);
            }
        },
        async mostrarHistorico() {
            try {
                const response = await api.get('dre/historico/arquivos');
                this.historico = response.data;
                this.modalHistorico = true;
            } catch (error) {
                console.error('Erro ao obter histórico:', error);
            }
        },
        fecharModalHistorico() {
            this.modalHistorico = false;
        },
        fecharModalEdicao() {
            this.modalEdicao = false;
        },
        formatarValor(value) {
            if (!isNaN(value)) {
                return 'R$ ' + parseFloat(value).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, '.');
            }
            return value;
        },
        formatarData(data) {
            const date = new Date(data);
            return date.toLocaleString();
        },
        fecharArquivo() {
            this.excelData = [];
            this.fileName = '';
        },

        async obterAnos() {
            try {
                const response = await anoexercicioService.obterAnoExercicio();
                this.anos = response.data;
            } catch (error) {
                console.error('Erro ao obter anos:', error);
            }
        },
        async adicionarAno() {
            try {
                await anoexercicioService.cadastrarAnoExercicio({ ano: this.ano });
                this.obterAnos();
                this.ano = '';
                this.modalArea = false;
            } catch (error) {
                console.error('Erro ao adicionar ano:', error);
            }
        },
        async excluirAno(id) {
            try {
                await anoexercicioService.excluirAnoExercicio(id);
                this.obterAnos();
            } catch (error) {
                console.error('Erro ao excluir ano:', error);
            }
        },
        uploadPlanilha() {},

        downloadPlanilha() {
            api.get('dre/excel/novo', { responseType: 'blob' })
                .then((response) => {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'filename.xlsx');
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                })
                .catch((error) => {
                    console.log(error);
                });
        },

        mostrarModalEdicao() {
            this.modalEdicao = true;
        },
        manual() {
            this.$router.push('/cadastrar/orcamento/manual');
        },
        planilha() {
            this.$router.push('/cadastrar/orcamento/planilha');
        },
    },
    computed: {
        filteredExcelData() {
            if (!this.searchTerm) {
                return this.excelData;
            }
            return this.excelData.filter(row => {
                return row.some(cell => {
                    return cell && cell.toString().toLowerCase().includes(this.searchTerm.toLowerCase());
                });
            });
        },
        filteredHistorico() {
            return this.historico.filter(item => {
                const itemDate = new Date(item.dtUpload).toISOString().split('T')[0];

                return (
                    (this.filtroAno === '' || item.ano == this.filtroAno) &&
                    (this.filtroUsuario === '' || item.usuario_nome.toLowerCase().includes(this.filtroUsuario.toLowerCase())) &&
                    (this.filtroData === '' || itemDate === this.filtroData)
                );
            });
        },
    },
};
</script>
