<template>
   
            <section>
                <div class="titulo">
                    <div class="margem container">
                       
                        <h2>Relatório Orçamento</h2>
                    </div>
                </div>
                <div class="margem container">

                <div class="margem container" style="cursor: pointer;">
                    <div class="  tags m-b">
                        <a @click="orcamentos" class="ativo">Orçamentos</a>
                        <a @click="geral" class="">Geral</a>
                        <a @click="dashboard" class="">Dashboard</a>
                    </div>
                    <div class="grid-4 bloco2 margem">
    
                        <div>
                            <label> Ano / Exercício: </label>
                            <select v-model="anoSelecionado">
                                                            <option value="" disabled>Selecione</option>
                                                            <option v-for="item in anos" :key="item.ano" :value="item.ano">
                                                                {{ item.ano }}
                                                            </option>
                                                        </select>
                        </div>
                        <div>
                            <label> Grupo de Contas: </label>
    
                            <select v-model="selectedGrupo" @change="updateSubgrupos">
                                                    <option value="" disabled>Selecione</option>
                                                    <option v-for="grupo in grupos" :key="grupo.id" :value="grupo">{{ grupo.descricaoDRE }}</option>
                                                  </select>
                        </div>
                        <div>
    
                            <label> Subgrupo: </label>
                            <select v-model="selectedSubgrupo" @change="updateCategorias">
                                                                        <option value="" disabled>Selecione</option>
                                                                        <option v-for="subgrupo in subgrupos" :key="subgrupo.id" :value="subgrupo">{{ subgrupo.descricaoDRE }}</option>
                                                                      </select>
    
                        </div>
                        <div>
                            <label> Categoria: </label>
                            <select v-model="selectedCategoria">
                                                                        <option value="" disabled>Selecione</option>
                                                                        <option v-for="categoria in categorias" :key="categoria.id" :value="categoria">{{ categoria.descricao }}</option></select>
                        </div>
                        <div>
                            <label> Família: </label>
                            <select>
                                                    <option value="" disabled>Selecione</option>
                                                  </select>
                        </div>
                    </div>
                    <div class="bloco margem ">
                    <div class="grid-3 ">
                            <div class="alinha-centro">
                                <h4>Orçamento Previsto</h4>
                                <div class="quadradoPrevisto">
                                    R$ 515.000
                                </div>
                            </div>
    
                            <div class="alinha-centro">
                                <h4>Orçamento Realizado</h4>
                                <div class="quadradoRealizado">
                                    R$ 15.000
                                </div>
                            </div>
                            <div class="alinha-centro">
                                <h4>Orçamento Disponível</h4>
                                <div class="quadradoDisponivel">
                                    R$ 500.000
    
                                </div>
                            </div>
                        </div>

                        <br>   <br><br>
                        <div class="table-container">
                        <table class="tabela">
                            <thead>
                                <tr>
                                    <th size="5" v-for="(month, index) in meses" :key="index">{{ month }}</th>
                                </tr>
                            </thead>
                         
                            <tbody>
                                <tr>
                                    <td v-for="(value, index) in valorMensal" :key="index" @click="toggleDetails(index)">
                                        <div style="text-align: left;">Previsto</div>
                                        <money3 size="8" style="opacity: 1; color: var(--cor-ok); border: none; background-color: transparent " :disabled="true" v-model="value.total" v-bind="config"></money3>
                                        <hr>
                                        <div style="text-align: left;">Realizado</div>
                                        <money3 size="8" style="opacity: 1;border: none; color: #FFA500; background-color: transparent;" :disabled="true" v-model="value.total" v-bind="config"></money3>
                                        <div v-if="value.showDetails">
                                            <hr>
                                            <div>Detalhes:</div>
                                            <div>Valor: R$ {{ value.valorExpandido }}</div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                      
                    </div>
                </div>
                </div>
            </section>
     
</template>

<script>
import { Money3Component } from 'v-money3';
import anoexercicioService from '@/services/ano-exercicio-service'
import estruturaService from '@/services/estrutura-service';


export default {
    components: {
        money3: Money3Component
    },
    data() {
        const currentYear = new Date().getFullYear();

        return {
            config: {
                masked: false,
                prefix: 'R$ ',
                thousands: '.',
                decimal: ',',
                precision: 2,
            },
            meses: [
                'Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho',
                'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'
            ],
            modalArea: false,
            mostrarInput: false,
            orcamentoDisponivel: 0,
            orcamentoDividido: 0,
            orcamentoTotal: null,
            checkBox: true,
            anos: '',

            anoSelecionado: currentYear,
            valorMensal: [
                { total: 710568, valorExpandido: 1, showDetails: false },
                { total: 9132142, valorExpandido: 2, showDetails: false },
                { total: 3928283, valorExpandido: 3, showDetails: false },
                { total: 655373, valorExpandido: 4, showDetails: false },
                { total: 4898223, valorExpandido: 5, showDetails: false },
                { total: 240244, valorExpandido: 6, showDetails: false },
                { total: 3928283, valorExpandido: 7, showDetails: false },
                { total: 6756083, valorExpandido: 8, showDetails: false },
                { total: 100, valorExpandido: 9, showDetails: false },
                { total: 9425167, valorExpandido: 10, showDetails: false },
                { total: 3928283, valorExpandido: 11, showDetails: false },
                { total: 9132142, valorExpandido: 12, showDetails: false },
            ],

            grupos: [],
            subgrupos: [],
            categorias: [],
            selectedGrupo: null,
            selectedSubgrupo: null,
            selectedCategoria: null
        };
    },


    methods: {
        async obterEstrutura() {
            try {
                const response = await estruturaService.obterEstrutura();
                this.grupos = response.data;
            } catch (error) {
                console.error('Erro ao obter estrutura:', error);
            }
        },
        updateSubgrupos() {
            if (this.selectedGrupo && this.selectedGrupo.subgrupo) {
                this.subgrupos = this.selectedGrupo.subgrupo;
            } else {
                this.subgrupos = [];
            }
            this.categorias = [];
            this.selectedSubgrupo = null;
            this.selectedCategoria = null;
        },
        updateCategorias() {
            if (this.selectedSubgrupo && this.selectedSubgrupo.categoriaDRE) {
                this.categorias = this.selectedSubgrupo.categoriaDRE;
            } else {
                this.categorias = [];
            }
            this.selectedCategoria = null;
        },
        async obterAnos() {
            try {
                const response = await anoexercicioService.obterAnoExercicio();
                this.anos = response.data;
            } catch (error) {
                console.error('Erro ao obter anos:', error);
            }
        },
        toggleDetails(index) {
            this.valorMensal[index].showDetails = !this.valorMensal[index].showDetails;
        },


        fecharModalFora(event) {
            if (event.target.classList.contains('modal-mask')) {
                this.modalArea = false;
            }
        },

        orcamentos() {
            this.$router.push('/relatorio/orcamento');
        },

        geral() {
            this.$router.push('/relatorio/geral');

        },
        dashboard() {
            this.$router.push('/relatorio/dashboard');

        },

    },




    mounted() {
        this.obterAnos()
        this.obterEstrutura()


    },
};
</script>

<style scoped>
.quadradoRealizado {
    border: 1px solid #ccc;
    border-radius: 15px;
    padding: 20px;
    text-align: center;
    background-color: #FFA500;
    color: var( --cor-bg);
    font-size: medium;
}

.quadradoPrevisto {
    border: 1px solid #ccc;
    border-radius: 15px;
    padding: 20px;
    text-align: center;
    background-color: var(--cor-ok);
    color: var( --cor-bg);
    font-size: medium;
}

.quadradoDisponivel {
    border: 1px solid #ccc;
    border-radius: 15px;
    padding: 20px;
    text-align: center;
    background-color: var(--cor-sucesso);
    color: var( --cor-bg);
    font-size: medium;
}
.table-container{
    overflow-x: auto;

}
</style>
