<template>
    
  
      
            <aside>
                <div class="titulo margem efeito">
                    <div class="m-icone"><a href="#" class="icone-menu" title="Menu" onclick="document.getElementById('main').classList.toggle('esconde');return false"></a></div>
                    <nav class="jm jm-inc none" id="menuIncluir">
                        <div class="separador">
                            <a href="#">Incluir projeto</a>
                            <a href="monitoramento.html">Incluir projeto</a>
                        </div>
                    </nav>
                </div>
                <nav class="nav-maior separador">
                  
                </nav>
                <nav class="nav-maior separador">
                    <router-link to="/" active-class="ativo">Plano de Contas</router-link>
                    <router-link to="/associar" active-class="ativo">Gerenciar Responsável</router-link>
                    <router-link to="/cadastrar/orcamento/manual" active-class="ativo">Cadastrar Orçamento</router-link>
                    <router-link to="/relatorio/orcamento" active-class="ativo">Relatórios</router-link>
                </nav>
            </aside>
           
</template>

<script>

export default {
    name: 'NovoMenuLateral',


    methods: {
        estrutura(){
            this.$router.push('/estrutura');
        },

        associar() {
            this.$router.push('/associar')
        },
        cadastrar() {
            this.$router.push('/cadastrar/orcamento/manual');
        },
        relatorio() {
            this.$router.push('/relatorio/orcamento');
        },
        orcamento() {
            this.$router.push('/orcamentos');
        },
    }

};
</script>
