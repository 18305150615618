import { createRouter, createWebHashHistory } from 'vue-router'
import { sso } from "roboflex-thalamus-sso-lib";

//Projeto
import Associar from '../views/Orçamentos/AssociarView.vue'

//Orçamento
import RelatorioOrcamento from '../views/Orçamentos/RelatorioOrcamento'
import OrcamentoPorProjeto from '../views/Orçamentos/OrçamentoPorProjeto.vue'
import CadastroOrcamentoPlanilha from '@/views/Orçamentos/CadastroOrcamentoPlanilha.vue'
import CadastroOrcamentoManual from '@/views/Orçamentos/CadastroOrcamentoManual.vue'
import OrcamentosView from '@/views/Orçamentos/OrcamentosView.vue'
import RelatorioDashboard from '@/views/Orçamentos/DashboardView.vue'

//Estrutura
import EstruturaPlano from '@/views/Orçamentos/EstruturaPlano.vue'

import teste from '@/views/Orçamentos/TesteView.vue'


function guardMyroute(to, from, next) {
  // next()
  if (sso.validarSessao()) {
    next();
  } else {
   

    document.location = process.env.VUE_APP_ROOT_SSO_LOGIN;
  }
}


const routes = [
  {
    path: "/login",
    redirect: "http://portal.thalamus.com.br/#/login",
  },


  {
    path: '/teste',
    name: 'teste',
    component: teste
  },



  //Estrutura
  {
    path: '/',
    name: 'EstruturaPlano',
    component: EstruturaPlano,
    beforeEnter: guardMyroute
  },

  //Projeto
  {
    path: '/associar',
    name: 'Associar',
    component: Associar,
    beforeEnter: guardMyroute

  },

  //Orçamento
  {
    path: '/relatorio/orcamento',
    name: 'RelatorioOrcamento',
    component: RelatorioOrcamento,
    beforeEnter: guardMyroute

  
  },
  {
    path: '/relatorio/dashboard',
    name: 'RelatorioDashboard',
    component: RelatorioDashboard,
    beforeEnter: guardMyroute

  },

  {
    path: '/cadastrar/orcamento/planilha',
    name: 'OrcamentoCadastroPlanilha',
    component: CadastroOrcamentoPlanilha,
    beforeEnter: guardMyroute

  },

  {
    path: '/cadastrar/orcamento/manual',
    name: 'OrcamentoCadastroManual',
    component: CadastroOrcamentoManual,
    beforeEnter: guardMyroute

  },

  {
    path: '/orcamento/projeto',
    name: 'OrcamentoProjeto',
    component: OrcamentoPorProjeto,
    beforeEnter: guardMyroute

  },

  {
    path: '/relatorio/geral',
    name: 'OrcamentosView',
    component: OrcamentosView,
    beforeEnter: guardMyroute

  },


]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
