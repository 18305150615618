<template>
    <div style="margin-left: 30rem;">
      <!-- <input type="text" v-model="searchQuery" @input="filterUsers" placeholder="Pesquisar colaborador por nome"> -->

                    <div style="display: flex; flex-flow: column; width: 100%; height: 10rem;">
                    <input type="text" v-model="searchQuery" @input="filterUsers" placeholder="Pesquisar colaborador por nome" class="form-control"
                        style="background-color: #f1f1f1; color: black; padding-top : 1.5rem; padding-bottom: 1.5rem; width: 30rem;"
                        >

                    <div style="height: fit-content; max-height: 15rem ; overflow: auto; background-color: #f1f1f1; border-bottom-left-radius: 15px; border-bottom-right-radius: 15px; position: absolute; margin-top: 3.3rem; width: 30rem;"
                        >
                        <ul style="list-style: none;">
                            <li v-for="user in filteredUsers"
                                :key="user.id">
                                <div style="display: flex; ; align-items: center; padding: 5px; border-radius: 10px; margin-right: 3rem;">
                                    {{ user.name }}
                                </div>
                            </li>
                        </ul>
                    </div>
                    </div>
      <!-- <ul v-if="filteredUsers.length">
        <li v-for="user in filteredUsers" :key="user.id" @click="usuarioSelecionado(user)">
          {{ user.name }}
        </li>
      </ul> -->
      <!-- <p v-else>Nenhum colaborador encontrado</p> -->
    </div>
  </template>
  
  <script>
import { api } from "roboflex-thalamus-request-handler";
  
  export default {
    data() {
      return {
        colaboradores: [],
        searchQuery: ''
      };
    },
    computed: {
      filteredUsers() {
        return this.colaboradores.filter(user =>
          user.name.toLowerCase().includes(this.searchQuery.toLowerCase())
        );
      }
    },
    mounted() {
      this.fetchUsers();
    },
    methods: {
      fetchUsers() {
        api.get('usuario')
          .then(response => {
            this.colaboradores = response.data.map(user => ({
              id: user.id,
              name: user.name
            }));
          })
          .catch(error => {
            console.error(error);
          });
      },
      filterUsers() {
      },
      usuarioSelecionado(user) {
        console.log('Usuário selecionado:', user);
      }
    }
  };
  </script>
  
  <style scoped>
  </style>
  