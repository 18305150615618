<template>
   
            <section>
                <div class="titulo">
                    <div class="margem container">
                        <h2>Geral</h2>
                    </div>
                </div>
                <div class="margem container">
    
                    <div class="margem container" style="cursor: pointer;">
                        <div class="  tags m-b">
                            <a @click="orcamentos" class="">Orçamentos</a>
                            <a @click="geral" class="ativo">Geral</a>
                            <a @click="dashboard" class="">Dashboard</a>
                        </div>
                        <div class="grid-4 bloco2 margem">
                            <div> 
                                <label> Ano / Exercício: </label>
                                <select v-model="anoSelecionado">
                                                <option value="" disabled>Selecione</option>
                                                <option v-for="item in anos" :key="item.ano" :value="item.ano">
                                                    {{ item.ano }}
                                                </option>
                                            </select></div>
    
                                        </div>
    
                        <br>
                        <div class="bloco margem">
                            <table class="tabela">
                                <thead>
                                    <tr>
                                        <th>Grupo </th>
                                        <th>Valor Previsto</th>
                                        <th>Valor Realizado</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <template v-for="(grupo, index) in grupos" :key="index">
                                        <tr>
                                            <td @click="toggleExpandir(grupo)"  >
                                                <b>
                                                    <i class="fa-regular"
                                                       :class="{'fa-square-caret-down': grupo.expandido, 'fa-square-caret-right': !grupo.expandido}"
                                                       style="cursor: pointer;"></i>
                                                    {{ grupo.descricaoDRE }}
                                                </b>
                                            </td>
                                            <td >
                                                <money3 style="border: none; opacity: 1; background-color: transparent; color: var(--cor-fonte-forte);" :disabled="true"
                                                        v-model="grupo.total_grupo_previsto" v-bind="config"></money3>
                                            </td>
                                            <td >
                                                <money3 style="border: none; opacity: 1; background-color: transparent; color: var(--cor-fonte-forte);" :disabled="true"
                                                        v-model="grupo.somaRealizado" v-bind="config"></money3>
                                            </td>
                                        </tr>
                                        <template v-for="(subgrupo, subindex) in grupo.subgrupo" :key="subindex">
                                            <tr v-show="grupo.expandido">
                                                <td class="subgrupo" @click="toggleExpandir(subgrupo)">
                                                    <b>
                                                        <i class="fa-regular"
                                                           :class="{'fa-square-caret-down': subgrupo.expandido, 'fa-square-caret-right': !subgrupo.expandido}"
                                                           style="cursor: pointer;"></i>
                                                        {{ subgrupo.descricaoDRE }}
                                                    </b>
                                                </td>
                                                <td>
                                                    <money3 style="border: none; opacity: 1; background-color: transparent; color: var(--cor-fonte-forte);" :disabled="true"
                                                            v-model="subgrupo.total_subgrupo_previsto" v-bind="config" ></money3>
                                                </td>
                                                <td>
                                                    <money3 style="border: none; opacity: 1; background-color: transparent; color: var(--cor-fonte-forte);" :disabled="true"
                                                            v-model="subgrupo.somaRealizado" v-bind="config"></money3>
                                                </td>
                                            </tr>
                                            <tr v-show="subgrupo.expandido" v-for="(categoria, catIndex) in subgrupo.categoriaDRE"
                                                :key="catIndex">
                                                <td>{{ categoria.descricao }}</td>
                                                <td>
                                                    <money3 style="border: none; opacity: 1; background-color: transparent; color: var(--cor-fonte-forte);" :disabled="true"
                                                            v-model="categoria.total_previsto" v-bind="config"></money3>
                                                </td>
                                                <td>
                                                    <money3 style="border: none; opacity: 1; background-color: transparent; color: var(--cor-fonte-forte);" :disabled="true"
                                                            v-model="categoria.valorRealizado" v-bind="config"></money3>
                                                </td>
                                            </tr>
</template>
                        </template>
                        </tbody>
                    </table>
                </div>
                </div>
            </div>
        </section>

</template>

<script>
import { Money3Component } from 'v-money3';
import anoexercicioService from '@/services/ano-exercicio-service';
import estruturaService from '@/services/estrutura-service';

export default {
    components: {
        money3: Money3Component,
    },
    data() {
        const currentYear = new Date().getFullYear();

        return {
            anos: [],
            anoSelecionado: currentYear,
            config: {
                masked: false,
                prefix: 'R$ ',
                thousands: '.',
                decimal: ',',
                precision: 2,
            },
            grupos: []
        };
    },
    methods: {
        async obterAnos() {
            try {
                const response = await anoexercicioService.obterAnoExercicio();
                this.anos = response.data;
            } catch (error) {
                console.error('Erro ao obter anos:', error);
            }
        },
        async obterEstrutura(ano) {
            try {
                const response = await estruturaService.obterEstrutura({ ano });
                this.grupos = response.data;
            } catch (error) {
                console.error('Erro ao obter estrutura:', error);
            }
        },

        orcamentos() {
            this.$router.push('/relatorio/orcamento');
        },

        geral() {
            this.$router.push('/relatorio/geral');
        },
        dashboard() {
            this.$router.push('/relatorio/dashboard');
        },

        toggleExpandir(item) {
            item.expandido = !item.expandido;
        },

        showBudgetColumn(item) {
            return typeof item.somaValorCategorias !== 'undefined';
        }
    },
    watch: {
        anoSelecionado(newAno) {
            this.obterEstrutura(newAno);
        }
    },

    mounted() {
        this.obterAnos();
        this.obterEstrutura(this.anoSelecionado);
    },
};
</script>
   