<template>
  
            <section>
                <div class="titulo">
                    <div class="margem container">
                        <div class="m-icone direita">
                            <div class="pesquisa">
                                <input type="text" placeholder="Pesquise aqui"/>
                                <a href="#" class="icone-pesquisa" title="Pesquise"></a>
                            </div>
                        </div>
                        <h2>Dashboard</h2>     
                    </div>
                </div>
                <div class="margem container">

                <div class="margem container" style="cursor: pointer;">
                    <div class="tags m-b">
                        <a @click="orcamentos" class="">Orçamentos</a>
                        <a @click="geral" class="">Geral</a>
                        <a @click="dashboard" class="ativo">Dashboard</a>
    
                    </div>
                    
                    <div class="bloco margem">
                       
                       
                       
                    </div>
                </div>
                </div>
            </section>
       
</template>

<script>
export default {
    data(){

    },

    methods: {
        orcamentos() {
            this.$router.push('/relatorio/orcamento');
        },

        geral() {
            this.$router.push('/relatorio/geral');

        },
        dashboard(){
            this.$router.push('/relatorio/dashboard');

        },
    }
}



</script>



 